var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-10 text-gray-700"},[(_vm.branches)?_c('ul',{staticClass:"branches-list list-reset"},_vm._l((_vm.branches),function(branch){return _c('li',{key:branch.id,staticClass:"items-center w-full bg-white rounded mb-4 border focus:outline-none focus:bg-blue-100",attrs:{"tabindex":"0"},on:{"contextmenu":function($event){$event.preventDefault();_vm.$can('manage', 'partner-database')
          ? _vm.$refs.branchMenu.open($event, {
              branch_id: branch.id,
              deleted_at: branch.deleted_at
            })
          : ''}}},[_c('div',{staticClass:"h-full flex justify-between flex-col"},[_c('div',{staticClass:"flex flex-col flex-1 p-6"},[_c('div',{staticClass:"inline-flex align-middle"},[_c('i',{staticClass:"material-icons fill-current mr-5"},[_vm._v("business")]),_c('span',{staticClass:"font-medium"},[_vm._v(" "+_vm._s(branch.name)+" ")])]),_c('div',{staticClass:"inline-flex items-center align-middle mt-5"},[_c('i',{staticClass:"material-icons md-18 fill-current mr-5"},[_vm._v("contact_mail")]),_c('span',{staticClass:"block"},[_vm._v(_vm._s(branch.email))])]),_c('div',{staticClass:"inline-flex items-center align-middle mt-3"},[_c('i',{staticClass:"material-icons md-18 fill-current mr-5"},[_vm._v("location_on")]),_c('span',{staticClass:"block"},[_vm._v(" "+_vm._s(branch.street || "-")+", "+_vm._s(branch.state)+", "+_vm._s(branch.country.country_name)+" ")])]),_c('div',{staticClass:"inline-flex items-center align-middle mt-3"},[_c('i',{staticClass:"material-icons md-18 fill-current mr-5"},[_vm._v("contact_phone")]),_c('span',{staticClass:"block"},[_vm._v(_vm._s(branch.phone_number || "N/A"))])])]),_c('div',{staticClass:"flex justify-between p-4 bg-gray-100 border-t"},[_c('div',[(branch.type == 'Head Office')?_c('span',{staticClass:"bg-blue-500 text-gray-200 inline-flex text-sm py-1 px-2 rounded"},[_vm._v("Head Office")]):_c('span',{staticClass:"bg-gray-600 text-gray-200 inline-flex text-sm py-1 px-2 rounded"},[_vm._v("Other Office")]),(branch.deleted_at)?_c('span',{staticClass:"ml-2 bg-red-500 py-1 px-2 inline-flex rounded text-red-100 text-sm"},[_vm._v("De-Activated")]):_vm._e()]),_c('button',{staticClass:"lg:hidden focus:outline-none py-1 inline-flex",on:{"click":function($event){_vm.$can('manage', 'partner-database')
                ? _vm.$refs.branchMenu.open($event, {
                    branch_id: branch.id,
                    deleted_at: branch.deleted_at
                  })
                : ''}}},[_c('i',{staticClass:"material-icons text-gray-700"},[_vm._v("more_vert")])])])])])}),0):_vm._e(),_c('ContextMenu',{ref:"branchMenu",staticClass:"flex-row",scopedSlots:_vm._u([{key:"default",fn:function(child){return [_c('div',{staticClass:"flex text-black opacity-75 cursor-pointer hover:bg-gray-200 mt-2",on:{"click":function($event){return _vm.edit(child.data.branch_id)}}},[_c('a',{staticClass:"no-underline text-current flex items-center py-2 w-full"},[_c('i',{staticClass:"material-icons md-18 fill-current mr-3 ml-5"},[_vm._v("edit")]),_c('div',[_vm._v("Edit")])])]),(!child.data.deleted_at)?_c('div',{staticClass:"flex text-black opacity-75 cursor-pointer hover:bg-gray-200 mb-2",on:{"click":function($event){return _vm.deactivate(child.data.branch_id)}}},[_c('a',{staticClass:"no-underline text-current flex items-center py-2 w-full"},[_c('i',{staticClass:"material-icons md-18 fill-current mr-3 ml-5"},[_vm._v("visibility_off")]),_c('div',[_vm._v("Deactivate")])])]):_vm._e(),(child.data.deleted_at)?_c('div',{staticClass:"flex text-black opacity-75 cursor-pointer hover:bg-gray-200 mb-2",on:{"click":function($event){return _vm.activate(child.data.branch_id)}}},[_c('a',{staticClass:"no-underline text-current flex items-center py-2 w-full"},[_c('i',{staticClass:"material-icons md-18 fill-current mr-3 ml-5"},[_vm._v("visibility")]),_c('div',[_vm._v("Activate")])])]):_vm._e(),_c('div',{staticClass:"flex text-black opacity-75 cursor-pointer hover:bg-gray-200 mb-2",on:{"click":function($event){return _vm.remove(child.data.branch_id)}}},[_c('a',{staticClass:"no-underline text-current flex items-center py-2 w-full"},[_c('i',{staticClass:"material-icons md-18 fill-current mr-3 ml-5"},[_vm._v("delete")]),_c('div',[_vm._v("Delete")])])])]}}])}),_c('div',[_c('TransitionPage',[_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }